@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: min(16px, 2.6vw);
    @apply bg-slate-950;
  }

  @media not screen and (hover) {
    * {
      cursor: default !important;
    }
  }

  .icon-button {
    @apply hover:bg-teal-500 rounded-full cursor-pointer hover:p-1 transition-all active:border-teal-950 active:border-2 active:bg-teal-200;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .blink {
    animation: blink 1s infinite;
    animation-timing-function: steps(1, end);
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fade-in 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-fill-mode: forwards;
  }

  .bg-gradient {
    background-color: #020617;
    background-image: radial-gradient(at 58% 58%, hsla(232, 47%, 11%, 1) 0px, transparent 50%), radial-gradient(at 80% 32%, hsla(232, 47%, 11%, 1) 0px, transparent 50%),
      radial-gradient(at 15% 86%, hsla(232, 47%, 11%, 1) 0px, transparent 50%), radial-gradient(at 28% 27%, hsla(168, 44%, 51%, 1) 0px, transparent 50%),
      radial-gradient(at 89% 99%, hsla(168, 44%, 51%, 1) 0px, transparent 50%);
  }

  body[data-modals-mounted] .modal-overlay {
    filter: brightness(0.5);
    pointer-events: none;
    transition: all 0.15s ease;
  }

  body[data-modals-mounted] .modal-overlay svg {
    @apply text-[#020617] opacity-[50%];
  }

  .input {
    @apply text-white text-base font-medium bg-white-10 rounded-xl px-4 py-2 placeholder:text-white placeholder:font-medium outline-none;
  }

  .btn-secondary {
    @apply w-max bg-slate-950 hover:bg-slate-900 px-8 transition-all text-white p-3 rounded-xl font-bold;
  }

  .btn-primary {
    @apply bg-white w-max px-8 hover:bg-slate-100 transition-all text-slate-950 p-3 rounded-xl font-bold;
  }

  .btn-selector {
    @apply w-max px-4 bg-white-10 hover:bg-slate-900 transition-all text-white py-2 rounded-xl font-medium;
  }

  .btn-selector.active {
    @apply bg-white hover:bg-slate-100 text-slate-950;
  }

  .clickable-link {
    @apply hover:underline cursor-pointer transition-all;
  }

  .toast-wrapper {
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
}
